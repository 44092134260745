import PropTypes from 'prop-types';
import React from 'react';
import styles from './CoachDetail.module.scss';

export default function CoachDetail({ coach }) {
  return (
    <React.Fragment>
      <img
        className={styles.image}
        alt={coach.profileImage.alt}
        src={coach.profileImage.src}
      />
      <div className={styles.name}>{coach.name}</div>
      <div className={styles.title}>{coach.title}</div>
    </React.Fragment>
  );
}

CoachDetail.propTypes = {
  coach: PropTypes.object.isRequired // TODO
};
