import { DOMAIN } from './index.js';

export function disableScrolling() {
  document.body.style.overflow = 'hidden';
}

export function enableScrolling() {
  document.body.style.overflow = '';
}

export function setMetaTag(attribute, attributeValue, newContentValue) {
  return [...document.getElementsByTagName('meta')]
    .find(el => el.getAttribute(attribute) === attributeValue)
    .setAttribute('content', newContentValue);
}

export function updateTags(title, description, pathname) {
  document.title = title;
  setMetaTag('name', 'description', description);
  setMetaTag('property', 'og:title', title);
  setMetaTag('property', 'og:description', description);
  setMetaTag('property', 'og:url', `${DOMAIN}/${pathname}`);
}
