import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getDataForSlug, tagToPath } from 'util/index.js';
import CoachDetail from './CoachDetail.js';
import CoachModal from './CoachModal.js';
import styles from './CoachesList.module.scss';

export default function CoachesList({ filter }) {
  const data = getDataForSlug('coaches');

  const [showCoach, setShowCoach] = useState(null);

  const filteredCoaches = data.coachesSection.coaches.filter(
    coach => filter === 'all' || coach.tags.map(tagToPath).includes(filter)
  );
  if (filteredCoaches.length === 0) return null;

  return (
    <div className={styles.coaches}>
      {filteredCoaches.map(coach => (
        <div
          className={styles.coach}
          key={coach.name}
          onClick={() => setShowCoach(coach)}>
          <CoachDetail coach={coach} />
        </div>
      ))}
      {showCoach && (
        <CoachModal coach={showCoach} onClose={() => setShowCoach(null)} />
      )}
    </div>
  );
}

CoachesList.propTypes = {
  filter: PropTypes.string
};
