import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import { getDataForSlug, tagToPath } from 'util/index.js';
import styles from './Navigation.module.scss';

export default function Navigation({ className }) {
  const data = getDataForSlug('strongline');

  const location = useLocation();
  const match = useRouteMatch();

  return (
    <div className={cn(styles.nav, className)}>
      {data.stronglineSection.tags.map(tag => (
        <NavLink
          activeClassName={styles.active}
          className={styles.navItem}
          exact
          key={tag.name}
          to={{
            pathname: `${match.url}/${tagToPath(tag.name)}`,
            search: location.search
          }}>
          {tag.name}
        </NavLink>
      ))}
    </div>
  );
}

Navigation.propTypes = {
  className: PropTypes.string
};
