import cn from 'classnames';
import React, { useEffect } from 'react';
import ActionLink from 'components/ActionLink';
import { ReactComponent as LaurelIcon } from 'images/laurel.svg';
import { updateTags } from 'util/dom.js';
import { getDataForSlug } from 'util/index.js';
import HeroContent from 'components/HeroContent';
import ReviewsPreview from 'components/ReviewsPreview';
import styles from './HomePage.module.scss';

export default function HomePage() {
  const data = getDataForSlug('home');

  useEffect(() => {
    updateTags(data.seoTitle, data.seoDescription, '');
  }, [data]);

  return (
    <React.Fragment>
      <HeroContent
        imageUrls={{
          mobile: data.homepageBanner.bannerImageMobile.src,
          desktop: data.homepageBanner.bannerImageDesktop.src
        }}>
        <span className="user-text">{data.homepageBanner.tagline}</span>
        <div>
          <ActionLink
            {...data.homepageBanner.firstButton}
            className={styles.purchaseAction}
            styleType="filled"
          />
          <ActionLink
            {...data.homepageBanner.secondButton}
            className={styles.newStudentAction}
            styleType="filled"
          />
        </div>
      </HeroContent>
      <div className={styles.section2}>
        <LaurelIcon className={styles.leftLaurel} />
        <div>
          Recognized as{' '}
          <div className={styles.callout}>BEST HIIT STUDIO IN DC </div>
          by the Washingtonian
        </div>
        <LaurelIcon className={styles.rightLaurel} />
      </div>
      <div className={styles.section3}>
        <div className={styles.col}>
          <h2 className={styles.title}>{data.aboutSection.title}</h2>
          <p
            className="user-text"
            dangerouslySetInnerHTML={{ __html: data.aboutSection.body }} />
          <ActionLink
            {...data.aboutSection.button}
            className={styles.learnMoreLink}
          />
        </div>
        <div className={cn(styles.col, styles.videoContainer)}>
          <iframe
            title="Intro video"
            className={styles.introVideo}
            src="https://www.youtube.com/embed/hkLWQeb3kVI"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        </div>
      </div>
      <ReviewsPreview />
    </React.Fragment>
  );
}
