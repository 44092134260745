import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/Modal';
import { ReactComponent as FacebookIcon } from 'images/social-facebook.svg';
import { ReactComponent as InstagramIcon } from 'images/social-instagram.svg';
import { ReactComponent as TwitterIcon } from 'images/social-twitter.svg';
import CoachDetail from './CoachDetail.js';
import styles from './CoachModal.module.scss';

export default function CoachModal({ coach, onClose }) {
  const facebookLink = coach.facebookLink && coach.facebookLink.url;
  const instagramLink = coach.instagramLink && coach.instagramLink.url;
  const twitterLink = coach.twitterLink && coach.twitterLink.url;
  const hasSocialLinks = facebookLink || instagramLink || twitterLink;

  return (
    <Modal onClose={onClose}>
      <div className={styles.coach}>
        <CoachDetail coach={coach} />
      </div>
      {hasSocialLinks && (
        <div className={styles.social}>
          {facebookLink && (
            <a
              className={styles.socialLink}
              href={facebookLink}
              rel="noopener noreferrer"
              target="_blank">
              <FacebookIcon className={styles.socialIcon} />
            </a>
          )}
          {instagramLink && (
            <a
              className={styles.socialLink}
              href={instagramLink}
              rel="noopener noreferrer"
              target="_blank">
              <InstagramIcon className={styles.socialIcon} />
            </a>
          )}
          {twitterLink && (
            <a
              className={styles.socialLink}
              href={twitterLink}
              rel="noopener noreferrer"
              target="_blank">
              <TwitterIcon className={styles.socialIcon} />
            </a>
          )}
        </div>
      )}
      <div
        className={styles.bio}
        dangerouslySetInnerHTML={{ __html: coach.bioHtml }}
      />
    </Modal>
  );
}

CoachModal.propTypes = {
  coach: PropTypes.object.isRequired // TODO
};
