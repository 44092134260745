import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { getDataForSlug, shorten } from 'util/index.js';
import { ReactComponent as XIcon } from 'images/x.svg';
import styles from './Modal.module.scss';

function disableScrolling() {
  document.body.style.overflow = 'hidden';
}

function enableScrolling() {
  document.body.style.overflow = '';
}

export default function({ children, onClose, imgUrl }) {
  const [mounted, setMounted] = useState(false);

  const el = useMemo(() => {
    const div = document.createElement('div');
    div.className = styles.modal;
    return div;
  }, []);

  useEffect(() => {
    function onKeydown(e) {
      if (e.keyCode === 27) onClose();
    }

    onClose && document.addEventListener('keydown', onKeydown, false);
    return () => document.removeEventListener('keydown', onKeydown, false);
  }, [onClose]);

  useEffect(() => {
    document.getElementById('modal-root').appendChild(el);
    setMounted(true);
    return () => {
      setMounted(false);
      document.getElementById('modal-root').removeChild(el);
    };
  }, [el]);

  useEffect(() => {
    disableScrolling();
    return enableScrolling;
  });

  // Wait until the component is mounted to render.
  // This allows things like autofocus to work in child components
  // (which require being mounted at render time)
  if (!mounted) return null;

  const data = getDataForSlug('popup');
  if (data.header && data.header.length > 35) {
    data.header = `${shorten(data.header, 35)}...`;
  }

  const modalEl = (
    <React.Fragment>
      <div className={styles.modalBackground}/>
      <div className={styles.modalContent}>
        {imgUrl && (
          <React.Fragment>
            <div
              className={styles.fullWidthImage}
              style={{
                backgroundImage:
                  'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),' +
                  `url('${imgUrl}')`
              }}>
              <div className={styles.header}>{data.header}</div>
            </div>
            <div className={styles.filler}></div>
          </React.Fragment>
        )}
        {onClose && (
          <XIcon
            className={ imgUrl ? styles.whiteCloseButton : styles.closeButton}
            onClick={onClose}
            title="close"
          />
        )}
        {children}
      </div>
    </React.Fragment>
  );

  return ReactDOM.createPortal(modalEl, el);
}
