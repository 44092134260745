// https://reacttraining.com/react-router/web/guides/scroll-restoration

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'util/hooks';

function getPrefix(pathname) {
  return pathname && pathname.split('/')[1];
}

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    if (getPrefix(prevPathname) !== getPrefix(pathname)) {
      window.scrollTo(0, 0);
    }
  }, [pathname, prevPathname]);

  return null;
}
