import React, { useEffect } from 'react';
import HeroContent from 'components/HeroContent';
import { updateTags } from 'util/dom.js';
import { getDataForSlug } from 'util/index.js'; // eslint-disable-line no-unused-vars
import styles from './SchedulePage.module.scss';

const mindbodyWidgetHTML =
  '<div class="mindbody-widget" data-widget-type="Schedules" data-widget-id="04743918dd"></div>';

const SchedulePage = () => {
  useEffect(() => {
    window['bw-widget-unique-identifier'] = false;
    // Load the widget script when the component is mounted or re-mounted
    const script = document.createElement('script');
    script.src = 'https://brandedweb.mindbodyonline.com/embed/widget.js';
    script.async = true;
    script.onload = () => {
      console.log('Mindbody widget script loaded');
    };
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []); // Dependency array ensures this runs only once on mount

  const data = getDataForSlug('schedule');
  updateTags(data.seoTitle, data.seoDescription, 'schedule');

  return (
    <React.Fragment>
      <HeroContent
        imageUrls={{
          desktop: data.banner.imageDesktop.src,
          mobile: data.banner.imageMobile.src
        }}
        mini>
        {data.banner.title}
      </HeroContent>
      <div
        dangerouslySetInnerHTML={{ __html: mindbodyWidgetHTML }}
        className={styles.schedulePage}
      />
    </React.Fragment>
  );
};

export default SchedulePage;
