import PropTypes from 'prop-types';
import React from 'react';
import ActionLink from 'components/ActionLink';
import styles from './Workout.module.scss';

export default function Workout({ workout }) {
  return (
    <React.Fragment>
      <h3>{workout.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: workout.descriptionHtml }} />
      <img
        alt={workout.image.alt}
        src={workout.image.src}
        className={styles.image}
      />
      <ActionLink {...workout.button} styleType="filled" />
    </React.Fragment>
  );
}

Workout.propTypes = {
  workout: PropTypes.object.isRequired
};
