export const DOMAIN = 'https://202strong.com';

export function getDataForSlug(slug) {
  return window.data202Strong.pages.find(page => page.slug === slug);
}

export function getDataForPosts() {
  return window.data202Strong.posts;
}

export function shorten(str, maxLen, separator = ' ') {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
}

export function tagToPath(tag) {
  return tag.replace(/\s/, '-');
}
