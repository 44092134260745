import React, { useEffect } from 'react';
import ActionLink from 'components/ActionLink';
import HeroContent from 'components/HeroContent';
import { updateTags } from 'util/dom.js';
import { getDataForSlug } from 'util/index.js'; // eslint-disable-line no-unused-vars
import styles from './ColdPlungePage.module.scss';

export default function IntroPage() {
  const data = getDataForSlug('cold-plunge');

  useEffect(() => {
    updateTags(data.seoTitle, data.seoDescription, 'cold-plunge');
  }, [data]);

  return (
    <React.Fragment>
      <HeroContent
        imageUrls={{
          desktop: data.banner.imageDesktop.src,
          mobile: data.banner.imageMobile.src
        }}
        mini>
        {data.banner.title}
      </HeroContent>
      <div className={styles.coldPlungeSection}>
        <h2>{data.coldPlungeSection.header}</h2>
        <p
          className={styles.coldPlungeDescription}
          dangerouslySetInnerHTML={{
            __html: data.coldPlungeSection.description
          }}></p>
        <ActionLink
          className={styles.button}
          {...data.coldPlungeSection.button}
          styleType="filled"
        />
      </div>
    </React.Fragment>
  );
}
