import React, { useEffect } from 'react';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import HeroContent from 'components/HeroContent';
import SideNavigation from 'components/SideNavigation';
import { updateTags } from 'util/dom.js';
import { getDataForSlug, tagToPath } from 'util/index.js';
import Packages from './Packages';
import styles from './PricingPage.module.scss';

export default function PricingPage() {
  const data = getDataForSlug('pricing');

  useEffect(() => {
    updateTags(data.seoTitle, data.seoDescription, 'pricing');
  }, [data]);

  const location = useLocation();
  const match = useRouteMatch();
  const filterMatch = useRouteMatch(`${match.url}/:packageType`);
  if (
    !filterMatch ||
    !data.pricingSection.tags
      .map(f => f.name)
      .map(tagToPath)
      .includes(filterMatch.params.packageType)
  ) {
    return (
      <Redirect
        to={{
          pathname: `${match.url}/intro-offers`,
          search: location.search
        }}
      />
    );
  }
  return (
    <React.Fragment>
      <HeroContent
        imageUrls={{
          desktop: data.banner.imageDesktop.src,
          mobile: data.banner.imageMobile.src
        }}
        mini>
        {data.banner.title}
      </HeroContent>
      <div className={styles.pricingSection}>
        <h2>{data.pricingSection.header}</h2>
        <SideNavigation
          links={data.pricingSection.tags.map(tag => ({
            label: tag.name,
            pathname: tagToPath(tag.name)
          }))}>
          <Packages
            filter={filterMatch.params.packageType}
            location={location}
          />
        </SideNavigation>
      </div>
    </React.Fragment>
  );
}
