import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './WideImage.module.scss';

export default function WideImage({ className, imageAlt, imageUrl, children }) {
  return (
    <div className={cn(styles.layout, className)}>
      <div className={styles.textCol}>{children}</div>
      <div className={styles.imageCol}>
        <img alt={imageAlt} src={imageUrl} className={styles.image} />
      </div>
    </div>
  );
}

Image.propTypes = {
  imageAlt: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  children: PropTypes.node
};
