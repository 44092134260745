import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import HeroContent from 'components/HeroContent';
import { updateTags } from 'util/dom.js';
import { getDataForSlug, tagToPath } from 'util/index.js';
import StrongLineList from './StrongLineList';
import Navigation from './Navigation';
import styles from './StrongLinePage.module.scss';

export default function StrongLinePage() {
  const data = getDataForSlug('strongline');

  useEffect(() => {
    updateTags(data.seoTitle, data.seoDescription, 'strongline');
  }, [data]);

  const el = useMemo(() => {
    const div = document.createElement('div');
    div.className = styles.modal;
    return div;
  }, []);

  useLayoutEffect(() => {
    if (localStorage && localStorage.getItem && localStorage.getItem('pw') !== data.pw.trim()) {
      document.getElementById('modal-root').appendChild(el);
    }
    setTimeout(() => {
      if (localStorage && localStorage.getItem && localStorage.getItem('pw') !== data.pw.trim()) {
        let password = 'wrongpassword';

        while ((password !== data.pw.trim()) && password !== null) {
          password = prompt('Please enter the strongline password to access this page');
        }
        document.getElementById('modal-root').removeChild(el);

        if (!password) {
          window.history.go(-1);
        } else {
          localStorage.setItem('pw', password);
        }
      }
    }, 300);
  }, [data, el]);

  const location = useLocation();
  const match = useRouteMatch();
  const filterMatch = useRouteMatch(`${match.url}/:filter`);
  if (
    !filterMatch ||
    !data.stronglineSection.tags
      .map(t => t.name)
      .map(tagToPath)
      .includes(filterMatch.params.filter)
  )
    return (
      <Redirect
        to={{ pathname: `${match.url}/all`, search: location.search }}
      />
    );

  return (
    <React.Fragment>
      <HeroContent
        imageUrls={{
          desktop: data.banner.imageDesktop.src,
          mobile: data.banner.imageMobile.src
        }}
        mini>
        {data.banner.title}
      </HeroContent>
      <div className={styles.stronglineSection}>
        <h2>{data.stronglineSection.header}</h2>
        <Navigation className={styles.nav} />
        <StrongLineList filter={filterMatch.params.filter}/>
      </div>
    </React.Fragment>
  );
}
