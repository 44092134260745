import React, { useEffect } from 'react';
import ActionLink from 'components/ActionLink';
import styles from './ReviewsPreview.module.scss';
import { getDataForSlug } from 'util/index.js';

export default function ReviewsPreview() {
  const data = getDataForSlug('home').reviewsSection;

  useEffect(() => {
    const script = document.createElement('script');

    // eslint-disable-next-line no-undef
    script.innerHTML = FRED.init({
      locationId: 'ee06b1e9-1273-4593-b990-c0b49bac5808',
      perPage: 5,
      layout: 'list',
      background: 'ffffff',
      titleHexColor: '8cc63f',
      starHexColor: 'fcc415'
    });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={styles.reviewsPreview}>
      <h2>{data.title}</h2>
      <div className={styles.reviews}>
        {[data.firstReview, data.secondReview, data.thirdReview].map(review => (
          <div className={styles.review} key={review.name}>
            <div className={styles.quote}>{review.text}</div>
            <div className={styles.author}>{review.name}</div>
          </div>
        ))}
      </div>
      <div className={styles.frederickReviews} data-fred-widget-reviews></div>
      <div className={styles.linkWrapper}>
        <ActionLink {...data.button} styleType="outline" />
      </div>
    </div>
  );
}
