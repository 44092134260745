import React from 'react';
import Modal from './Modal';
import ActionLink from 'components/ActionLink';
import { getDataForSlug } from 'util/index.js';
import styles from './HomePageModal.module.scss';

export default function HomePageModal({ onClose }) {
  const data = getDataForSlug('popup');
  return (
    <Modal
      onClose={onClose}
      imgUrl={data.img.src}>
      <div className={styles.header}>
        {data.text}
      </div>
      <ActionLink
        type={data.button.type}
        target={data.button.target}
        text={data.button.text}
        link={data.button.link}
        className={styles.actionLink}
        styleType="filled"
      />
    </Modal>
  );
}

