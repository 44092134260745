import PropTypes from 'prop-types';
import React from 'react';
import styles from './StrongLineDetail.module.scss';

const defaultPostImg = 'https://images.prismic.io/202strong/461a3661-ecf0-41c1-a224-6fdbeffb077f_strong_line.png?auto=compress,format&rect=0,50,1121,747&w=600&h=400';

export default function StrongLineDetail({ strongLineVideo }) {
  const twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 3);
  const postDate = new Date(strongLineVideo.date)

  const isNewVideo = postDate > twoDaysAgo;
  const dateFormat = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

  return (
    <a
      href={strongLineVideo.postLink}
      rel="noopener noreferrer"
      target="_blank"
      className={styles.link}>
      <div className={styles.strongLineContainer}>
        {isNewVideo && <div className={styles.newVideo}>New!</div>}
        <img
          className={styles.image}
          src={strongLineVideo.postImg || defaultPostImg}
          alt='Strongline Video Post'
        />
        <div className={styles.date}>Posted on {dateFormat.format(postDate)}</div>
        <div className={styles.title}>{strongLineVideo.title}</div>
      </div>
    </a>
  );
}

StrongLineDetail.propTypes = {
  strongLineVideo: PropTypes.object.isRequired // TODO
};
