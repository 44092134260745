import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import 'whatwg-fetch';
import { ReactComponent as EnvelopeIcon } from 'images/envelope.svg';
import { ReactComponent as MapMarkerIcon } from 'images/map-marker.svg';
import { ReactComponent as SocialFacebookIcon } from 'images/social-facebook.svg';
import { ReactComponent as SocialInstagramIcon } from 'images/social-instagram.svg';
import { ReactComponent as SocialTwitterIcon } from 'images/social-twitter.svg';
import styles from './Footer.module.scss';
import HomePageModal from './HomePageModal.js';
import { getDataForSlug } from 'util/index.js';

const healcodeWidgetHTML =
  '<healcode-widget data-version="0.2" data-link-class="loginRegister" data-site-id="56465" data-mb-site-id="548478" data-bw-identity-site="false" data-type="account-link" data-inner-html="Login | Register"  />';

export default function Footer() {
  const location = useLocation();
  // const [email, setEmail] = useState('');
  // const [signupLoading, setSignupLoading] = useState(false);
  // const [signupResponse, setSignupResponse] = useState(null);
  // const [signupError, setSignupError] = useState(null);
  const [homePageModal, setHomePageModal] = useState(null);

  const data = getDataForSlug('popup');
  if (data.showPopup && location.pathname === '/') {
    data.showPopup = false;
    setTimeout(() => {
      setHomePageModal(true);
    }, 1000);
  }

  // async function onSubmit(e) {
  //   e.preventDefault();
  //   setSignupLoading(true);
  //   try {
  //     const response = await fetch(
  //       'https://website-202strong.herokuapp.com/api/v1/202strong/email-list',
  //       {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify({ email })
  //       }
  //     );
  //     const json = await response.json();
  //     if (response.status === 200) {
  //       setSignupResponse(json.message);
  //     } else {
  //       setSignupError(json.message);
  //     }
  //   } finally {
  //     setSignupLoading(false);
  //   }
  // }
  // const signupForm = (
  //   <form onSubmit={onSubmit}>
  //     <input
  //       className={styles.emailInput}
  //       onChange={e => setEmail(e.target.value)}
  //       placeholder="Email Address"
  //       type="email"
  //       required
  //     />
  //     {signupError && <div className={styles.error}>{signupError}</div>}
  //     <button className={styles.emailButton} type="submit">
  //       {signupLoading ? 'Submitting…' : 'Signup'}
  //     </button>
  //   </form>
  // );

  return (
    <div className={styles.footer}>
      <div className={styles.col}>
        <div className={styles.title}>202strong</div>
        <div dangerouslySetInnerHTML={{ __html: healcodeWidgetHTML }}></div>
        {/* <div className={styles.title}>Want to hear from us?</div>
        Sign up for the Strong Blog to get the latest in fitness and nutrition
        {!signupResponse ? (
          signupForm
        ) : (
          <div className={styles.success}>{signupResponse}</div>
        )} */}
      </div>
      <div className={styles.col}>
        <div className={styles.title}>Links</div>
        <div>
          <Link
            className={styles.link}
            to={{
              pathname: '/schedule',
              search: location.search
            }}>
            Class Schedule
          </Link>
          <Link
            className={styles.link}
            to={{
              pathname: '/programs/personal-training',
              search: location.search
            }}>
            Personal Training
          </Link>
          <Link
            className={styles.link}
            to={{
              pathname: '/cold-plunge',
              search: location.search
            }}>
            Cold Plunge
          </Link>
          <Link
            className={styles.link}
            to={{
              pathname: '/terms',
              search: location.search
            }}>
            Terms
          </Link>
          <a
            className={styles.link}
            title="MindBody Schedule"
            href="https://clients.mindbodyonline.com/classic/ws?studioid=548478"
            target="_blank"
            rel="noopener noreferrer">
            MindBody
          </a>
          <a
            className={styles.link}
            target="_blank"
            href="https://www.stronglinefitness.com"
            rel="noopener noreferrer">
            About StrongLine
          </a>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.title}>Contact</div>
        <div className={styles.contactRow}>
          <EnvelopeIcon className={styles.contactIcon} />
          <a href="mailto:friends@202strong.com">friends@202strong.com</a>
        </div>
        <div className={styles.contactRow}>
          <MapMarkerIcon className={styles.contactIcon} />
          <a href="https://goo.gl/maps/LduP5tS5g4unbBHcA">
            NORTH BETHESDA: 12077 Nebel Street, North Bethesda, MD 20852
          </a>
        </div>
        <a className={styles.socialLink} href="http://twitter.com/202strong">
          <SocialTwitterIcon />
        </a>
        <a className={styles.socialLink} href="http://facebook.com/202strong">
          <SocialFacebookIcon />
        </a>
        <a
          className={styles.socialLink}
          href="https://www.instagram.com/202_strong">
          <SocialInstagramIcon />
        </a>
      </div>
      {homePageModal && (
        <HomePageModal onClose={() => setHomePageModal(null)} />
      )}
    </div>
  );
}
