import React, { useEffect } from 'react';
import ActionLink from 'components/ActionLink';
import HeroContent from 'components/HeroContent';
import ImageLayout from 'layouts/Image';
import WideImageLayout from 'layouts/WideImage';
import { updateTags } from 'util/dom.js';
import { getDataForSlug } from 'util/index.js'; // eslint-disable-line no-unused-vars
import styles from './IntroPage.module.scss';

export default function IntroPage() {
  const data = getDataForSlug('intro');

  useEffect(() => {
    updateTags(data.seoTitle, data.seoDescription, 'intro');
  }, [data]);

  return (
    <React.Fragment>
      <HeroContent
        imageUrls={{
          desktop: data.banner.imageDesktop.src,
          mobile: data.banner.imageMobile.src
        }}
        mini>
        {data.banner.title}
      </HeroContent>
      <div className={styles.introPage}>
        {data.newStudentSections.map((section, index) => {
          if (index % 2 === 0) {
            return (
              <ImageLayout
                key={section.label}
                className={styles.section}
                imageAlt={section.image.alt}
                imageUrl={section.image.src}>
                <h4 className={styles.centerAlignMobile}>{section.label}</h4>
                <h3 className={styles.centerAlignMobile}>{section.header}</h3>
                <p className="user-text">{section.description}</p>
                <ActionLink {...section.button} className={styles.button} />
              </ImageLayout>
            );
          } else {
            return (
              <WideImageLayout
                key={section.label}
                className={styles.section}
                imageAlt={section.image.alt}
                imageUrl={section.image.src}>
                <h4 className={styles.centerAlignMobile}>{section.label}</h4>
                <h3 className={styles.centerAlignMobile}>{section.header}</h3>
                <p className="user-text">{section.description}</p>
                <ActionLink {...section.button} className={styles.button} />
              </WideImageLayout>
            );
          }
        })}
      </div>
      <HeroContent
        imageUrls={{
          desktop:
            'https://res.cloudinary.com/zenrez/image/upload/v1571853634/temp/background3.jpg'
        }}>
        { data.callToActionSection.header }
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.title}>{data.callToActionSection.firstResponse}</div>
            <ActionLink
              {...data.callToActionSection.firstButton}
              className={styles.purchaseAction}
              styleType="filled"
            />
          </div>
          <div className={styles.col}>
            <div className={styles.title}>{data.callToActionSection.secondResponse}</div>
            <ActionLink
              {...data.callToActionSection.secondButton}
              className={styles.newStudentAction}
              styleType="filled"
            />
          </div>
        </div>
      </HeroContent>
    </React.Fragment>
  );
}
