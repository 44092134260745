import React, { useEffect } from 'react';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import HeroContent from 'components/HeroContent';
import SideNavigation from 'components/SideNavigation';
import { updateTags } from 'util/dom.js';
import { getDataForSlug, tagToPath } from 'util/index.js'; // eslint-disable-line no-unused-vars
import Workout from './Workout';
import styles from './WorkoutPage.module.scss';

export default function WorkoutPage() {
  const data = getDataForSlug('programs');

  useEffect(() => {
    updateTags(data.seoTitle, data.seoDescription, 'programs');
  }, [data]);

  const location = useLocation();
  const match = useRouteMatch();
  const programMatch = useRouteMatch(`${match.url}/:program`);
  if (
    !programMatch ||
    !data.programsSection.tags
      .map(tagToPath)
      .includes(programMatch.params.program)
  ) {
    return (
      <Redirect
        to={{
          pathname: `${match.url}/${tagToPath(data.programsSection.tags[0])}`,
          search: location.search
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <HeroContent
        imageUrls={{
          desktop: data.banner.imageDesktop.src,
          mobile: data.banner.imageMobile.src
        }}
        mini>
        {data.banner.title}
      </HeroContent>
      <div className={styles.workoutPage}>
        <h2>{data.programsSection.header}</h2>
        <SideNavigation
          links={data.programsSection.tags.map(t => ({
            label: t,
            pathname: tagToPath(t)
          }))}>
          <Workout
            workout={data.programsSection.programs.find(
              p => tagToPath(p.tag) === programMatch.params.program
            )}
          />
        </SideNavigation>
      </div>
    </React.Fragment>
  );
}
