import PropTypes from 'prop-types';
import React from 'react';
import { getDataForPosts } from 'util/index.js';
import StrongLineDetail from './StrongLineDetail.js';
import styles from './StrongLineList.module.scss';

export default function StrongLineList({ filter }) {
  const posts = getDataForPosts();

  const filteredStrongLineVideos = posts.filter(
    strongLineVideo => filter === 'all' || (strongLineVideo.instructorName && strongLineVideo.instructorName.indexOf(filter) > -1)
  );
  if (filteredStrongLineVideos.length === 0) return null;

  return (
    <div className={styles.videos}>
      {filteredStrongLineVideos.map((strongLineVideo, i) => (
        <div
          className={styles.video}
          key={i}>
          <StrongLineDetail strongLineVideo={strongLineVideo} />
        </div>
      ))}
    </div>
  );
}

StrongLineList.propTypes = {
  filter: PropTypes.string
};
