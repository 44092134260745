import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styles from './ActionLink.module.scss';

export function InternalActionLink({
  className,
  pathname,
  styleType = 'filled',
  ...proxiedProps
}) {
  const location = useLocation();
  return (
    <Link
      {...proxiedProps}
      to={{ pathname: pathname, search: location.search }}
      className={cn(styles.actionLink, styles[styleType], className)}
    />
  );
}
InternalActionLink.propTypes = {
  className: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  styleType: PropTypes.oneOf(['outline', 'filled'])
};

export function ExternalActionLink({
  className,
  children,
  styleType = 'filled',
  ...proxiedProps
}) {
  if (proxiedProps.target === '_blank') {
    proxiedProps.rel = 'noopener noreferrer';
  }

  return (
    <a
      {...proxiedProps}
      className={cn(styles.actionLink, styles[styleType], className)}>
      {children}
    </a>
  );
}
ExternalActionLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  styleType: PropTypes.oneOf(['outline', 'filled'])
};

export default function ActionLink({ type, text, link, ...proxiedProps }) {
  switch (type) {
    case 'external':
      return (
        <ExternalActionLink {...proxiedProps} href={link}>
          {text}
        </ExternalActionLink>
      );
    case 'internal':
    default:
      return (
        <InternalActionLink {...proxiedProps} pathname={link}>
          {text}
        </InternalActionLink>
      );
  }
}
ActionLink.propTypes = {
  type: PropTypes.oneOf(['external', 'internal'])
};
