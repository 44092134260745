import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './HeroContent.module.scss';

const MOBILE_BREAKPOINT = 767;

export default function HeroContent({ className, imageUrls, children, mini }) {
  const isMobile = window.innerWidth < MOBILE_BREAKPOINT;
  let imageUrl = isMobile ? imageUrls.mobile : imageUrls.desktop;
  imageUrl = imageUrl || imageUrls.desktop; // Fall back to desktop image

  return (
    <div
      className={cn(styles.heroContent, className, mini ? styles.mini : '')}
      style={{
        backgroundImage:
          'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),' +
          `url('${imageUrl}')`,
        backgroundPosition: mini ? 'center 24%' : 'center'
      }}>
      {children}
    </div>
  );
}
HeroContent.propTypes = {
  imageUrls: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string.isRequired
  }).isRequired,
  children: PropTypes.node
};
