import React, { useEffect } from 'react';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import HeroContent from 'components/HeroContent';
import { updateTags } from 'util/dom.js';
import { getDataForSlug, tagToPath } from 'util/index.js';
import CoachesList from './CoachesList';
import Navigation from './Navigation';
import styles from './CoachesPage.module.scss';

export default function CoachesPage() {
  const data = getDataForSlug('coaches');

  useEffect(() => {
    updateTags(data.seoTitle, data.seoDescription, 'coaches');
  }, [data]);

  const location = useLocation();
  const match = useRouteMatch();
  const filterMatch = useRouteMatch(`${match.url}/:filter`);
  if (
    !filterMatch ||
    !data.coachesSection.tags
      .map(t => t.name)
      .map(tagToPath)
      .includes(filterMatch.params.filter)
  )
    return (
      <Redirect
        to={{ pathname: `${match.url}/all`, search: location.search }}
      />
    );

  return (
    <React.Fragment>
      <HeroContent
        imageUrls={{
          desktop: data.banner.imageDesktop.src,
          mobile: data.banner.imageMobile.src
        }}
        mini>
        {data.banner.title}
      </HeroContent>
      <div className={styles.coachesSection}>
        <h2>{data.coachesSection.header}</h2>
        <Navigation className={styles.nav} />
        <CoachesList filter={filterMatch.params.filter} />
      </div>
    </React.Fragment>
  );
}
