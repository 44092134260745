import humps from 'humps';
import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import ColdPlungePage from 'components/ColdPlungePage/ColdPlungePage';
import CoachesPage from 'components/CoachesPage/CoachesPage';
import Footer from 'components/Footer';
import HomePage from 'components/HomePage';
import IntroPage from 'components/IntroPage/IntroPage';
import LoadingSpinner from 'components/LoadingSpinner';
import PricingPage from 'components/PricingPage/PricingPage';
import TermsPage from 'components/TermsPage/TermsPage';
import Navigation from 'components/Navigation';
import ScrollToTop from 'components/ScrollToTop';
import WorkoutPage from 'components/WorkoutPage/WorkoutPage';
import SchedulePage from 'components/SchedulePage/SchedulePage';
import SchedulesPage from 'components/SchedulePage/SchedulesPage';
import StrongLinePage from 'components/StrongLinePage/StrongLinePage';
import { useFetch } from 'util/hooks.js';
import styles from './App.module.scss';
import './globals.scss';
import GA from 'util/GoogleAnalytics';

function App() {
  const { data, loading, error } = useFetch(
    'https://website-202strong.herokuapp.com/api/v1/202strong/all'
  );

  if (loading) return <LoadingSpinner />;
  if (error || !data) return null;

  window.showPopup = true;
  window.data202Strong = humps.camelizeKeys(data.data); // Shortcut until we need redux or a proper store

  return (
    <Router>
      {GA.init({ code: window.data202Strong.googleAnalyticsCode }) && (
        <GA.RouteTracker />
      )}
      <ScrollToTop />
      <div className={styles.app}>
        <Navigation />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/programs" component={WorkoutPage} />
          <Route path="/cold-plunge" component={ColdPlungePage} />
          <Route path="/coaches" component={CoachesPage} />
          <Route path="/pricing" component={PricingPage} />
          <Route path="/intro" component={IntroPage} />
          <Route path="/schedule" component={SchedulePage} />
          <Route path="/schedules" component={SchedulesPage} />
          <Route path="/strongline" component={StrongLinePage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
        <div id="modal-root" />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
