import cn from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import SlideLeft from 'animations/SlideLeft.module.scss';
import { ReactComponent as BarsIcon } from 'images/bars.svg';
import { ReactComponent as XIcon } from 'images/x.svg';
import { disableScrolling, enableScrolling } from 'util/dom.js';
import { usePrevious } from 'util/hooks.js';
import styles from './Navigation.module.scss';

const NAV_HEIGHT = 44 /* nav item height */ + 30 * 2; /* navigation padding */

const HOME_LINK = { pathname: '/', label: 'Home', exact: true };

const LINKS = [
  {
    pathname: '/programs',
    label: 'The Workout'
  },
  {
    pathname: '/cold-plunge',
    label: 'Cold Plunge'
  },
  {
    pathname: '/coaches',
    label: 'Our Coaches'
  },
  {
    pathname: '/pricing',
    label: 'Pricing'
  },
  {
    pathname: '/intro',
    label: 'New to 202 Strong?'
  }
];

function MobileNav({ scrolled, location, setShowMobileMenu }) {
  return (
    <div
      className={cn(styles.mobileNavigation, {
        [styles.scrolled]: scrolled
      })}>
      <BarsIcon
        className={styles.hamburgerIcon}
        onClick={() => setShowMobileMenu(true)}
      />
      <Link
        to={{
          pathname: '/',
          search: location.search
        }}>
        <img
          alt="202 Strong logo"
          className={cn(styles.logo, 'h-margin-md')}
          src="https://i.imgur.com/fvnBJnL.png"
        />
      </Link>
      <Link
        className={styles.outlineNavItem}
        to={{ pathname: '/schedule', search: location.search }}>
        Book
      </Link>
    </div>
  );
}

function MobileMenu({ location, setShowMobileMenu }) {
  return (
    <div className={styles.mobileMenu}>
      <XIcon
        className={styles.closeIcon}
        onClick={() => setShowMobileMenu(false)}
      />
      {[HOME_LINK].concat(LINKS).map(link => (
        <NavLink
          activeClassName={styles.active}
          className={styles.menuItem}
          exact={link.exact}
          key={link.pathname}
          onClick={() => setShowMobileMenu(false)}
          to={{
            pathname: link.pathname,
            search: location.search
          }}>
          {link.label}
        </NavLink>
      ))}
      <NavLink
        activeClassName={styles.active}
        className={styles.menuItem}
        onClick={() => setShowMobileMenu(false)}
        to={{
          pathname: '/strongline',
          search: location.search
        }}>
        StrongLine
      </NavLink>
    </div>
  );
}

export default function Navigation() {
  const location = useLocation();
  const prevPathname = usePrevious(location.pathname);
  const desktopNavRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    if (prevPathname !== location.pathname) setShowMobileMenu(false);
  }, [location.pathname, prevPathname]);

  useLayoutEffect(() => {
    function handleResize() {
      const navHeight = desktopNavRef.current.offsetHeight;
      setMobile(navHeight > NAV_HEIGHT); // As soon as the nav wraps, display the mobile nav
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useLayoutEffect(() => {
    function handleScroll() {
      setScrolled(window.scrollY > 0);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    if (showMobileMenu) disableScrolling();
    else enableScrolling();
    return enableScrolling;
  }, [showMobileMenu]);

  return (
    <React.Fragment>
      <div
        className={cn(styles.navigation, {
          [styles.mobile]: mobile,
          [styles.scrolled]: scrolled
        })}
        ref={desktopNavRef}>
        <div className={styles.leftNav}>
          <Link
            to={{
              pathname: '/',
              search: location.search
            }}>
            <img
              alt="202 Strong logo"
              className={cn(styles.logo, 'h-margin-md')}
              src="https://i.imgur.com/fvnBJnL.png"
            />
          </Link>
          {LINKS.map(link => (
            <NavLink
              activeClassName={styles.active}
              className={styles.navItem}
              key={link.pathname}
              to={{
                pathname: link.pathname,
                search: location.search
              }}>
              {link.label}
            </NavLink>
          ))}
        </div>
        <div>
          <Link
            className={styles.outlineNavItem}
            to={{ pathname: '/strongline', location: location.search }}>
            StrongLine
          </Link>
          <Link
            className={styles.outlineNavItem}
            to={{ pathname: '/schedule', location: location.search }}>
            Book now
          </Link>
        </div>
      </div>
      {mobile && (
        <MobileNav
          scrolled={scrolled}
          location={location}
          setShowMobileMenu={setShowMobileMenu}
        />
      )}
      <CSSTransition
        in={showMobileMenu}
        timeout={300}
        classNames={SlideLeft}
        unmountOnExit>
        <MobileMenu location={location} setShowMobileMenu={setShowMobileMenu} />
      </CSSTransition>
    </React.Fragment>
  );
}
