import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import styles from './SideNavigation.module.scss';

export default function SideNavigation({ children, links }) {
  const location = useLocation();
  const match = useRouteMatch();
  return (
    <div className={styles.row}>
      <div className={styles.nav}>
        {links.map(link => (
          <NavLink
            activeClassName={styles.active}
            className={styles.navItem}
            key={link.pathname}
            to={{
              pathname: `${match.url}/${link.pathname}`,
              search: location.search
            }}>
            {link.label}
          </NavLink>
        ))}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

SideNavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      pathname: PropTypes.string.isRequired
    })
  ).isRequired
};
