import React from 'react';
import { ExternalActionLink } from 'components/ActionLink';
import { getDataForSlug, tagToPath } from 'util/index.js';
import styles from './Packages.module.scss';

export default function Packages({ filter }) {
  const data = getDataForSlug('pricing');
  const filteredPackages = data.pricingSection.packages.filter(p =>
    p.tags.map(tagToPath).includes(filter)
  );
  if (filteredPackages.length === 0) return null;
  return (
    <div className={styles.packages}>
      {filteredPackages.map(p => (
        <div className={styles.package} key={p.name}>
          <div className={styles.label}>{p.name}</div>
          <div className={styles.price}>{p.price}</div>
          <div className={styles.buy}>
            <ExternalActionLink
              href={p.url}
              target="_blank"
              rel="noopener noreferrer">
              Buy Now
            </ExternalActionLink>
          </div>
        </div>
      ))}
    </div>
  );
}
